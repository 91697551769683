/* .all{
    display: flex;
  } */

  @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  overflow-x: hidden;
}
  /*  */

  .container {
    /* margin: 20 20px; */
    
  } 
  
  @media (min-width: 768px) {
    .container {
      width: 700px;
      margin: 0px;
      margin-left: auto;
      margin-right: auto;
    }
  
    /* .container {
      margin: 30px 30px 80px 30px;
    } */
  
  }
  
  
  @media (min-width: 992px) {
    .container {
      width: 1200px;
      margin: 0px;
      margin-left: auto;
      margin-right: auto;
  
    }
  }
  
  


/* .container{
  padding:40px 30px;
}
@media (min-width: 768px) {
  .container{
    padding:40px 80px;
  }
}

@media (min-width: 992px) {
  .container{
    padding:60px 80px 20px;
  }
} */



.client_slider {

  .slick-prev,
  .slick-next {
    font-size: 0;
    background-color: transparent;
    border: none;
    top: 100%;
    z-index: 1;
  }
img{
  padding: 25px;
}
@media (min-width: 768px) {
  img{
  
  }
}
@media (min-width: 992px) {
  img{
  padding-top:50px;
  }
}

  .slick-prev:before {
    content: "";
    display: block;
    width: 30px;
    height: 40px;
    background-image: url("../assets/img/sliderarrowleft.webp");
    background-size: cover;
  }
  @media (min-width: 768px) {
    .slick-prev:before {
      content: "";
      display: block;
      width: 50px;
      height: 50px;
      background-image: url("../assets/img/sliderarrowleft.webp");
      background-size: cover;
    }
  }

  @media (min-width: 992px) {
    .slick-prev:before {
      content: "";
      display: block;
      width: 50px;
      height: 50px;
      background-image: url("../assets/img/sliderarrowleft.webp");
      background-size: cover;
    }
  }
  .slick-next:before {
    content: "";
    display: block;
    width: 30px;
    height: 40px;
    background-image: url("../assets/img/sliderarrowright.webp");
    background-size: cover;

  }

  @media (min-width: 768px) {
    .slick-next:before {
      content: "";
      display: block;
      width: 50px;
      height: 50px;
      background-image: url("../assets/img/sliderarrowright.webp");
      background-size: cover;
  
    }
  
  }
  @media (min-width: 992px) {
    .slick-next:before {
      content: "";
      display: block;
      width: 50px;
      height: 50px;
      background-image: url("../assets/img/sliderarrowright.webp");
      background-size: cover;
  
    }
  
  }
  .slick-prev {
    top: 50%;
    left: -12px;
  }
    @media (min-width: 768px) {
      .slick-prev {
        top: 50%;
      }
    }
    @media (min-width: 992px) {
      .slick-prev {
        top: 50%;
      }
       
    }
  
  .slick-next {
    top: 50%;
    right: 15px;
  }
    @media (min-width: 768px) {
      .slick-next {
        top: 50%;
        right: 10px;
      }
    }
    @media (min-width: 992px) {
      .slick-next {
        top: 50%;
        right: 10px;
      }
    }
  
}
.slick-list {
  margin: 0px;
  gap: 0px; /* Adjust the negative margin to half of the desired gap */
}

.slick-slide {
  padding: 0px;
  gap: 0px; /* Adjust the padding to match the negative margin above */
}
.gallery {
  display: flex;
}

.image-container {
  position: relative;
  overflow: hidden;
 height: 400px;
  margin-right: 0; /* Adjust the spacing between images */
  transition: width 0.5s; /* Add transition for slow-motion effect */
}
.overlay.overlay-mobile{
  display: none;
}
@media (max-width: 768px) {
  .image-container {
    width: 100%;
  }
  .overlay.overlay-mobile{
    display: flex;
  }
}
@media (min-width: 768px) {
  .image-container {
    position: relative;
    overflow: hidden;
  
   height: 400px;
    margin-right: 20px; /* Adjust the spacing between images */
    transition: width 0.5s; /* Add transition for slow-motion effect */
  }

}
@media (min-width: 992px) {

  .image-container {
    position: relative;
    overflow: hidden;
   height: 400px;
    margin-right: 20px; /* Adjust the spacing between images */
    transition: width 0.5s; /* Add transition for slow-motion effect */
  }
  .image-container:last-child{
    margin-right: 0;
  }

}
/* .image-container:hover {
  width: 350px;
} */
/* @media (min-width: 768px) {
  .image-container:hover {
    width: 400px;
  } */
/* 
}
@media (min-width: 992px) {

  .image-container:hover {
    width: 541px;
  }

} */
.image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-container img {
  width: 100%;
   height: 100%;;
  transition: transform 0.5s;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  transition: opacity 0.5s;
}

/* .image-container:hover .image {
  transform: scale(1.4);
} */

/* .image-container:hover .overlay {
  opacity: 1;
} */

h2, p {
  margin: 0;
  font-size: 1rem;
}



.top_btn .chatWithUs {
  position: fixed;
  bottom: 135px; 
  right:35px;
  margin-bottom: 0;
  /* height: 54px;
  padding: 10px 5px 10px 10px;
  font-size: 13px;
  font-weight: 600;
  color: #9d7b24;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #fff;
  border: solid 1.5px #cc9933;
  overflow: hidden;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  box-shadow: 1px 2px 7px rgba(000, 000, 000, 0.20);
  -moz-box-shadow: 1px 2px 7px rgba(000, 000, 000, 0.20);
  -webkit-box-shadow: 1px 2px 7px rgba(000, 000, 000, 0.20);
  animation: hithere 2s ease infinite;
  span{
    width: 85px;
    display: block;
    text-align: center;
    margin-left: 3px;
  } */
}
  .top_btn .chatWithUs img{
    /* width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 18px;
    -moz-border-radius: 18px;
    -webkit-border-radius: 18px; */
  }
  @media (min-width: 992px) {
    /* left: inherit;
    right:25px;
    margin-bottom: 45px; */
  }


.top_btn .myBtn {
  /* position: fixed;
  bottom: 10px;
  float: right;
  right:25px;
  margin-bottom: 80px; */
}

@media (min-width: 768px) {
  .top_btn .myBtn {
    /* position: fixed;
    bottom: 10px;
    float: right;
    margin-bottom: 80px;
    right:25px;  */

  }

}


.top_btn .myBtn1 {
  position: fixed;
  bottom: 75px;
  float: right;
  right: 20px;
}



@media (min-width: 768px) {
  /* .top_btn .myBtn1 {
    position: fixed;
    bottom: 10px;
    float: right;
  } */
}

@media (min-width: 992px) {

  /* .top_btn .myBtn1 {
    position: fixed;
    bottom: 10px;
    float: right;
    left: 94.25%
    } */

}
.circular-progress {
  transform: rotate(0deg);
}
 
.progress-ring {
  transition: stroke-dashoffset 0.35s;
}


.wrapper{
  width: 1000px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.wrapper .card{
  background: #fff;
     width: calc(25% - 8px);
  height: 300px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  box-shadow: 0px 10px 15px rgba(0,0,0,0.1);
}
.wrapper .card .circle{
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  cursor: default;
}
.card .circle .box,
.card .circle .box span{
  position: absolute;
  top: 50%;
  left: 50%;
}
.card .circle .box{
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  transition: all 0.2s;
}
.card .circle:hover .box{
  transform: translate(-50%, -50%) scale(0.91);
}
.card .circle .box span,
.wrapper .card .text{
  background: -webkit-linear-gradient(left, #a445b2, #fa4299);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.circle .box span{
  font-size: 38px;
  font-family: sans-serif;
  font-weight: 600;
  transform: translate(-45%, -45%);
  transition: all 0.1s;
}
.card .circle:hover .box span{
  transform: translate(-45%, -45%) scale(1.09);
}
.card .text{
  font-size: 20px;
  font-weight: 600;
}
@media(max-width: 753px){
  .wrapper{
    max-width: 700px;
  }
  .wrapper .card{
    width: calc(50% - 20px);
    margin-bottom: 20px;
  }
}
@media(max-width: 505px){
  .wrapper{
    max-width: 500px;
  }
  .wrapper .card{
    width: 100%;
  }
}
.galleryimg3{
  object-fit: cover;
}
.aboutimg{
  background-image: url("../assets/img/bg-img-about.webp");
  background-size: cover;
}
.testimonialbg{
  background-image: url("../assets/img/testimonailbg.webp");
  background-size: cover;
  padding: 40px;
}

.bgimgfreequently{
  background-image: url("../assets/img/bg-img-freequently.webp");
  background-size: cover;
  padding: 60px;

}
.Bg_img_contact{
  background-image: url("../assets/img/bg-img-contact.webp");
  background-size: cover;
  padding: 60px;
}
.Bg_choose_us{
  background-image: url("../assets/img/bg-choose-us.webp");
  background-size: cover;
 
  margin-top: 100px;
 
}